const { buffer } = require('gulp-util');
const axios = require('axios');


document.addEventListener("DOMContentLoaded", function (event) {

	const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)
	if (isSafari) {
		document.querySelector('body').classList.add('safari')
	}

	//Mobile menu
	let hamburger = document.querySelector('.hamburger');
	let mobileNav = document.querySelector('.simple-nav');
	let menuContainer = mobileNav.querySelector('.menu-main-menu-container');

	menuContainer.innerHTML += '<a class="new-button new-button-green" style="margin: 30px auto 0;" href="' + homeUrl + '/kontakt/ ">Kontakt</a>';

	if (typeof (hamburger) != 'undefined' && hamburger != null) {
		hamburger.addEventListener('click', function () {
			if (mobileNav.classList.contains('active')) {
				mobileNav.classList.remove('active');
			} else {
				mobileNav.classList.add('active');
			}
		})
	}

	//Dropdown submenu
	let menus = document.querySelectorAll('.menu-item-has-children');

	if (typeof (menus) != 'undefined' && menus != null) {
		menus.forEach(menu => {
			menu.addEventListener('click', function () {
				if (menu.classList.contains('open')) {
					menu.classList.remove('open');
				} else {
					menu.classList.add('open');
				}
			})
		})
	}

	document.addEventListener('click', (event) => {
		menus.forEach(menu => {
			if (!menu.contains(event.target)) {
				menu.classList.remove('open');
			}
		})
	});


	// FAQ
	var faqs = document.querySelectorAll('.faq-box');
	var answers = document.querySelectorAll('.answer');

	for (var i = 0; i < faqs.length; i++) {

		faqs[i].onclick = function (event) {

			var currentItem = this;

			if (!currentItem.classList.contains('active')) {

				[].forEach.call(faqs, function (el) {
					el.classList.remove('active')
				});

				currentItem.classList.add('active')

			} else {

				currentItem.classList.remove('active')

			}
		}
	}

	// FAQ
	var projectNewsItems = document.querySelectorAll('.js-news-header');

	for (var i = 0; i < projectNewsItems.length; i++) {

		projectNewsItems[i].onclick = function (event) {

			var currentItem = this;
			var parentItem = this.parentNode;

			if (!parentItem.classList.contains('active')) {

				parentItem.classList.add('active')

			} else {

				parentItem.classList.remove('active')

			}
		}
	}

	var selectForm = document.getElementById('formSelect');
	if (selectForm) {
		checkFaultReport()

		selectForm.addEventListener("change", function () {
			checkFaultReport();
		});
	}
	function checkFaultReport() {
		console.log('RUN');

		var checkboxHolder = document.getElementById('checkbox-holder')
		var adressHolder = document.getElementById('formAdress')
		if (selectForm.value === 'Felanmälan') {
			checkboxHolder.classList.remove('hidden')
			adressHolder.classList.remove('hidden')
		} else {
			checkboxHolder.classList.add('hidden')
			adressHolder.classList.add('hidden')
		}

	}
	// Interest Form.
	var submitInterestForm = document.getElementById('interestSubmit');
	if (submitInterestForm) {
		submitInterestForm.addEventListener("click", (e) => {
			e.preventDefault();
			submitInterestForm.disabled = true;

			var fields = {
				'type': document.getElementById('interest').dataset.type,
				'name': document.getElementById('interestName').value,
				'phone': document.getElementById('interestPhone').value,
				'email': document.getElementById('interestEmail').value,
				'message': document.getElementById('interestMessage').value
			}
			/**
			 * Save to  admin
			 */
			axios.post('/wp-json/westerlinds/form/interest', fields)
				.then(response => {
					document.getElementById('interest').innerHTML = '<h3 class="title-m white">Tack för din intresseanmälan.</h3>';
				})
				.catch(error => {
					document.getElementById('interestErrorMessage').innerText = 'Något gick fel. Försök igen.';
					submitInterestForm.disabled = false;
				})

		});
	}

	// Error Form.
	var submitErrorForm = document.getElementById('faultSubmit');
	if (submitErrorForm) {
		submitErrorForm.addEventListener("click", (e) => {
			e.preventDefault();
			submitErrorForm.disabled = true;

			var fields = {
				'type': document.getElementById('faultForm').dataset.type,
				'select': document.getElementById('formSelect').value,
				'name': document.getElementById('formName').value,
				'adress': document.getElementById('formAdress').value,
				'phone': document.getElementById('formPhone').value,
				'email': document.getElementById('formEmail').value,
				'key': document.getElementById('formKey').checked,
				'message': document.getElementById('formMessage').value
			}
			/**
			 * Save to  admin
			 */
			const axios = require('axios');
			axios.post('/wp-json/westerlinds/form/fault', fields)
				.then(response => {
					document.getElementById('faultForm').innerHTML = '<h3 class="title-m heading">Tack för ditt meddelande. Vi återkommer så fort vi kan.</h3>';
				})
				.catch(error => {
					document.getElementById('interestErrorMessage').innerText = 'Något gick fel. Försök igen.';
					submitInterestForm.disabled = false;
				})
		});
	}

	// Jobs Form.
	var submitJobForm = document.getElementById('jobSubmit');
	if (submitJobForm) {
		submitJobForm.addEventListener("click", (e) => {
			e.preventDefault();
			submitJobForm.disabled = true;

			var fields = {
				'name': document.getElementById('formName').value,
				'phone': document.getElementById('formPhone').value,
				'email': document.getElementById('formEmail').value,
				'message': document.getElementById('formMessage').value
			}
			/**
			 * Save to  admin
			 */
			const axios = require('axios');
			axios.post('/wp-json/westerlinds/form/job', fields)
				.then(response => {
					document.getElementById('jobForm').innerHTML = '<h3 class="title-m">Tack för ditt meddelande.</h3>';
				})
				.catch(error => {
					document.getElementById('formErrorMessage').innerText = 'Något gick fel. Försök igen.';
					submitInterestForm.disabled = false;
				})

		});
	}

	//Project filter
	let projectChevron = document.querySelector('.js-project-select-chevron');
	let projectSelect = document.querySelector('.js-project-select-wrapper');
	let projectFilterButtons = document.querySelectorAll('.js-project-filter-btn');
	let projectCards = document.querySelectorAll('.project-card');
	// const projectFilterCats = document.querySelectorAll('.js-cat-description');

	if (typeof (projectChevron) != 'undefined' && projectChevron != null) {
		projectChevron.addEventListener('click', function () {
			if (projectChevron.classList.contains('active-chevron')) {
				projectChevron.classList.remove('active-chevron')
				projectSelect.style.borderRadius = '100px';
				projectSelect.style.paddingTop = '0px';

				projectFilterButtons.forEach(button => {
					if (!button.classList.contains('active')) {
						button.style.display = 'none';
					}
				})

			} else {
				projectChevron.classList.add('active-chevron')
				projectSelect.style.borderRadius = '32px';
				projectSelect.style.paddingTop = '62px';

				projectFilterButtons.forEach(button => {
					button.style.display = 'block';
				})
			}
		})
	}

	if (typeof (projectFilterButtons) != 'undefined' && projectFilterButtons != null) {
		projectFilterButtons.forEach(button => {
			button.addEventListener('click', function () {
				projectFilterButtons.forEach(button => {
					button.classList.remove('active');
				})
				projectSelect.style.paddingTop = '0px';


				button.classList.add('active');

				projectFilterButtons.forEach(button => {
					if (!button.classList.contains('active')) {
						button.style.display = 'none';
					}
				})

				projectChevron.classList.remove('active-chevron')
				projectSelect.style.paddingTop = '0px';

				projectCards.forEach(card => {
					if (button.dataset.category == 'all') {
						card.classList.remove('cat-hide');
					} else {
						if (!card.classList.contains(button.dataset.category)) {
							card.classList.add('cat-hide');
						} else {
							card.classList.remove('cat-hide');
						}
					}
				})

				// projectFilterCats.forEach(cat => {
				// 	cat.classList.remove('show');

				// 	if (cat.dataset.category == button.dataset.category) {
				// 		cat.classList.add('show');
				// 	}
				// });
			})
		})
	}

	// Project filer location
	let projectLocationChevron = document.querySelector('.js-project-location-chevron');
	let projectLocationSelect = document.querySelector('.js-project-location-wrapper');
	let projectLocationFilterButtons = document.querySelectorAll('.js-project-location-btn');

	if (typeof (projectLocationChevron) != 'undefined' && projectLocationChevron != null) {
		projectLocationChevron.addEventListener('click', function () {
			if (projectLocationChevron.classList.contains('active-chevron')) {
				projectLocationChevron.classList.remove('active-chevron')
				projectLocationSelect.style.borderRadius = '100px';
				projectLocationSelect.style.paddingTop = '0px';

				projectLocationFilterButtons.forEach(button => {
					if (!button.classList.contains('active')) {
						button.style.display = 'none';
					}
				})

			} else {
				projectLocationChevron.classList.add('active-chevron')
				projectLocationSelect.style.borderRadius = '32px';
				projectLocationSelect.style.paddingTop = '62px';

				projectLocationFilterButtons.forEach(button => {
					button.style.display = 'block';
				})
			}
		})
	}

	if (typeof (projectLocationFilterButtons) != 'undefined' && projectLocationFilterButtons != null) {
		projectLocationFilterButtons.forEach(button => {
			button.addEventListener('click', function () {
				projectLocationFilterButtons.forEach(button => {
					button.classList.remove('active');
				})

				button.classList.add('active');

				projectLocationFilterButtons.forEach(button => {
					if (!button.classList.contains('active')) {
						button.style.display = 'none';
					}
				})

				projectLocationChevron.classList.remove('active-chevron')
				projectLocationSelect.style.paddingTop = '0px';


				projectCards.forEach(card => {
					if (button.dataset.category == 'all') {
						card.classList.remove('loc-hide');
					} else {
						if (!card.classList.contains(button.dataset.category)) {
							card.classList.add('loc-hide');
						} else {
							card.classList.remove('loc-hide');
						}
					}
				})
			})
		})

	}


	//Realestate filter
	let realestateChevron = document.querySelector('.js-realestate-select-chevron');
	let realestateSelect = document.querySelector('.js-realestate-select-wrapper');
	let realestateFilterButtons = document.querySelectorAll('.js-realestate-filter-btn');
	const realestateHolder = document.getElementById('realestate-holder');

	if (typeof (realestateHolder) != 'undefined' && realestateHolder != null) {
		getRealestates();
	}

	if (typeof (realestateChevron) != 'undefined' && realestateChevron != null) {
		realestateChevron.addEventListener('click', function () {
			if (realestateChevron.classList.contains('active-chevron')) {
				realestateChevron.classList.remove('active-chevron')
				realestateSelect.style.borderRadius = '100px';
				realestateSelect.style.paddingTop = '0px';


				realestateFilterButtons.forEach(button => {
					if (!button.classList.contains('active')) {
						button.style.display = 'none';
					}
				})

			} else {
				realestateChevron.classList.add('active-chevron')
				realestateSelect.style.borderRadius = '32px';
				realestateSelect.style.paddingTop = '62px';

				realestateFilterButtons.forEach(button => {
					button.style.display = 'block';
				})
			}
		})
	}

	if (typeof (realestateFilterButtons) != 'undefined' && realestateFilterButtons != null) {
		realestateFilterButtons.forEach(button => {
			button.addEventListener('click', function () {
				realestateFilterButtons.forEach(button => {
					button.classList.remove('active');
				})
				button.classList.add('active');

				realestateFilterButtons.forEach(button => {
					if (!button.classList.contains('active')) {
						button.style.display = 'none';
					}
				})

				realestateChevron.classList.remove('active-chevron')
				realestateSelect.style.paddingTop = '0px';

				getRealestates(button.dataset.category)
			})
		})
	}

	function getRealestates(category) {

		var data = {
			action: "get_realestate",
			category: category,
		};

		axios
			.get(AJAX_URL, { params: data })
			.then((response) => {
				realestateHolder.innerHTML = response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	//Premises filter
	let premisesChevron = document.querySelector('.js-premises-select-chevron');
	let premisesSelect = document.querySelector('.js-premises-select-wrapper');
	let premisesFilterButtons = document.querySelectorAll('.js-premises-filter-btn');
	const premisesHolder = document.getElementById('premises-holder');

	if (typeof (premisesHolder) != 'undefined' && premisesHolder != null) {
		getPremises();
	}

	if (typeof (premisesChevron) != 'undefined' && premisesChevron != null) {
		premisesChevron.addEventListener('click', function () {
			if (premisesChevron.classList.contains('active-chevron')) {
				premisesChevron.classList.remove('active-chevron')
				premisesSelect.style.borderRadius = '100px';
				premisesSelect.style.paddingTop = '0px';

				premisesFilterButtons.forEach(button => {
					if (!button.classList.contains('active')) {
						button.style.display = 'none';
					}
				})

			} else {
				premisesChevron.classList.add('active-chevron')
				premisesSelect.style.borderRadius = '32px';
				premisesSelect.style.paddingTop = '62px';

				premisesFilterButtons.forEach(button => {
					button.style.display = 'block';
				})
			}
		})
	}

	if (typeof (premisesFilterButtons) != 'undefined' && premisesFilterButtons != null) {
		premisesFilterButtons.forEach(button => {
			button.addEventListener('click', function () {
				premisesFilterButtons.forEach(button => {
					button.classList.remove('active');
				})
				button.classList.add('active');

				premisesFilterButtons.forEach(button => {
					if (!button.classList.contains('active')) {
						button.style.display = 'none';
					}
				})

				premisesChevron.classList.remove('active-chevron')
				premisesSelect.style.paddingTop = '0px';

				getPremises(button.dataset.category)
			})
		})
	}

	function getPremises(category) {

		var data = {
			action: "get_premises",
			category: category,
		};

		axios
			.get(AJAX_URL, { params: data })
			.then((response) => {
				premisesHolder.innerHTML = response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}
});

// Employee filer
let employeeChevron = document.querySelector('.js-employee-chevron');
let employeeSelect = document.querySelector('.js-employee-wrapper');
let employeeFilterButtons = document.querySelectorAll('.js-employee-btn');
let employeeCards = document.querySelectorAll('.employee-card');


if (typeof (employeeChevron) != 'undefined' && employeeChevron != null) {
	employeeChevron.addEventListener('click', function () {
		if (employeeChevron.classList.contains('active-chevron')) {
			employeeChevron.classList.remove('active-chevron')
			employeeSelect.style.borderRadius = '100px';
			employeeSelect.style.paddingTop = '0px';

			employeeFilterButtons.forEach(button => {
				if (!button.classList.contains('active')) {
					button.style.display = 'none';
				}
			})

		} else {
			employeeChevron.classList.add('active-chevron')
			employeeSelect.style.borderRadius = '32px';
			employeeSelect.style.paddingTop = '62px';

			employeeFilterButtons.forEach(button => {
				button.style.display = 'block';
			})
		}
	})
}

if (typeof (employeeFilterButtons) != 'undefined' && employeeFilterButtons != null) {
	employeeFilterButtons.forEach(button => {
		button.addEventListener('click', function () {
			employeeFilterButtons.forEach(button => {
				button.classList.remove('active');
			})

			button.classList.add('active');

			employeeFilterButtons.forEach(button => {
				if (!button.classList.contains('active')) {
					button.style.display = 'none';
				}
			})

			employeeChevron.classList.remove('active-chevron')
			employeeSelect.style.paddingTop = '0px';

			employeeCards.forEach(card => {
				card.style.display = 'inline-block';

				if (card.dataset.category !== button.dataset.category && button.dataset.category !== 'all') {
					card.style.display = 'none';
				}
			})
		})
	})
}

//Image carousel
const carouselFrames = Array.from(document.querySelectorAll('.carousel-frame'));

function makeCarousel(frame) {
	const carouselSlide = frame.querySelector('.carousel-slide');
	const carouselImages = getImagesPlusClones();
	const prevBtn = frame.querySelector('.carousel-prev');
	const nextBtn = frame.querySelector('.carousel-next');
	const navDots = Array.from(frame.querySelectorAll('.carousel-dots li'));

	let imageCounter = 1;

	function getImagesPlusClones() {
		let images = frame.querySelectorAll('.carousel-slide img');

		const firstClone = images[0].cloneNode();
		const lastClone = images[images.length - 1].cloneNode();

		firstClone.className = 'first-clone';
		lastClone.className = 'last-clone';

		// we need clones to make an infinite loop effect
		carouselSlide.append(firstClone);
		carouselSlide.prepend(lastClone);

		// must reassign images to include the newly cloned images
		images = frame.querySelectorAll('.carousel-slide img');

		return images;
	}

	function initializeNavDots() {
		if (navDots.length) navDots[0].classList.add('active-dot');
	}

	function initializeCarousel() {
		carouselSlide.style.transform = 'translateX(-100%)';
	}

	function slideForward() {
		// first limit counter to prevent fast-change bugs
		if (imageCounter >= carouselImages.length - 1) return;
		carouselSlide.style.transition = 'transform 400ms';
		imageCounter++;
		carouselSlide.style.transform = `translateX(-${100 * imageCounter}%)`;
	}

	function slideBack() {
		// first limit counter to prevent fast-change bugs
		if (imageCounter <= 0) return;
		carouselSlide.style.transition = 'transform 400ms';
		imageCounter--;
		carouselSlide.style.transform = `translateX(-${100 * imageCounter}%)`;
	}

	function makeLoop() {
		// instantly move from clones to originals to produce 'infinite-loop' effect
		if (carouselImages[imageCounter].classList.contains('last-clone')) {
			carouselSlide.style.transition = 'none';
			imageCounter = carouselImages.length - 2;
			carouselSlide.style.transform = `translateX(-${100 * imageCounter}%)`;
		}

		if (carouselImages[imageCounter].classList.contains('first-clone')) {
			carouselSlide.style.transition = 'none';
			imageCounter = carouselImages.length - imageCounter;
			carouselSlide.style.transform = `translateX(-${100 * imageCounter}%)`;
		}
	}

	function goToImage(e) {
		carouselSlide.style.transition = 'transform 400ms';
		imageCounter = 1 + navDots.indexOf(e.target);
		carouselSlide.style.transform = `translateX(-${100 * imageCounter}%)`;
	}

	function highlightCurrentDot() {
		navDots.forEach((dot) => {
			if (navDots.indexOf(dot) === imageCounter - 1) {
				dot.classList.add('active-dot');
			} else {
				dot.classList.remove('active-dot');
			}
		});
	}

	function addBtnListeners() {
		nextBtn.addEventListener('click', slideForward);
		prevBtn.addEventListener('click', slideBack);
	}

	function addNavDotListeners() {
		navDots.forEach((dot) => {
			dot.addEventListener('click', goToImage);
		});
	}

	function addTransitionListener() {
		carouselSlide.addEventListener('transitionend', () => {
			makeLoop();
			highlightCurrentDot();
		});
	}

	function autoAdvance() {
		let play = setInterval(slideForward, 5000);

		frame.addEventListener('mouseover', () => {
			clearInterval(play); // pause when mouse enters carousel
		});

		frame.addEventListener('mouseout', () => {
			play = setInterval(slideForward, 5000); // resume when mouse leaves carousel
		});

		document.addEventListener('visibilitychange', () => {
			if (document.hidden) {
				clearInterval(play); // pause when user leaves page
			} else {
				play = setInterval(slideForward, 5000); // resume when user returns to page
			}
		});
	}

	function buildCarousel() {
		initializeCarousel();
		initializeNavDots();
		addNavDotListeners();
		addBtnListeners();
		addTransitionListener();
		autoAdvance();
	}

	buildCarousel();
}

carouselFrames.forEach(frame => makeCarousel(frame));





function Marquee(selector, speed) {
	const parentSelector = document.querySelector(selector);
	const clone = parentSelector.innerHTML;
	const firstElement = parentSelector.children[0];
	let i = 0;
	parentSelector.insertAdjacentHTML('beforeend', clone);
	parentSelector.insertAdjacentHTML('beforeend', clone);

	setInterval(function () {
		firstElement.style.marginLeft = `-${i}px`;
		if (i > firstElement.clientWidth) {
			i = 0;
		}
		i = i + speed;
	}, 0);
}

//after window is completed load
//1 class selector for marquee
//2 marquee speed 0.2
window.addEventListener('load', Marquee('.marquee', 0.2))